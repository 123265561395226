<template>
	<el-dialog
		:title="title"
		:visible.sync="visible"
		:lock-scroll="false"
		width="80%"
		@close="close"
		center
	>
		<div class="content">
			<div class="form">
				<el-form>
					<el-row>
						<el-col :span="12">
							<el-form-item label="保证金银行卡号：">
								<el-input style="width: 240px" v-model="form.account"></el-input> </el-form-item
						></el-col>
						<el-col :span="12">
							<el-form-item label="保证金开户行：">
								<el-input style="width: 240px" v-model="form.khh"></el-input> </el-form-item
						></el-col>
						<el-col :span="12">
							<el-form-item label="保证金开户名称：">
								<el-input style="width: 240px" v-model="form.khm"></el-input> </el-form-item
						></el-col>
						<el-col :span="12">
							<el-form-item label="行政区：">
								<el-cascader
									style="width: 240px"
									v-model="form.xzqbh"
									:props="prop"
									:options="regionOptions"
									:show-all-levels="false"
								></el-cascader> </el-form-item
						></el-col>
						<el-col :span="12">
							<el-form-item label="监管银行卡号：">
								<el-input style="width: 240px" v-model="form.jgAccount"></el-input> </el-form-item
						></el-col>
						<el-col :span="12">
							<el-form-item label="监管开户行：">
								<el-input style="width: 240px" v-model="form.jgKhh"></el-input> </el-form-item
						></el-col>
						<el-col :span="12">
							<el-form-item label="监管开户名称：">
								<el-input style="width: 240px" v-model="form.jgKhm"></el-input> </el-form-item
						></el-col>
						<el-col :span="12">
							<el-form-item label="监管省份：">
								<el-input style="width: 240px" v-model="form.jgProvince"></el-input> </el-form-item
						></el-col>
						<el-col :span="12">
							<el-form-item label="监管市：">
								<el-input style="width: 240px" v-model="form.jgCity"></el-input> </el-form-item
						></el-col>
						<el-col :span="12">
							<el-form-item label="监管区/县：">
								<el-input style="width: 240px" v-model="form.jgArea"></el-input> </el-form-item
						></el-col>
						<el-col :span="12">
							<el-form-item label="	农户身份证号：">
								<el-input style="width: 240px" v-model="form.orderCardId"></el-input>
							</el-form-item>
						</el-col>
            <el-col :span="12">
							<el-form-item label="公司统一社会信用代码：">
								<el-input style="width: 240px" v-model="form.socialCreditId"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="保证金开户行收款码：">
								<span style="color: red">(支持：JPG/JPEG/GIF/PNG格式。)</span>
								<el-upload
									ref="pic1"
									multiple
									action=""
									name="files"
									:limit="1"
									:file-list="fileList1"
									list-type="picture-card"
									:before-upload="beforeUpload"
									:on-change="upload"
									:on-remove="removeXczp"
									:auto-upload="false"
								>
									<i slot="default" title="选取文件" class="el-icon-plus"></i>
								</el-upload>
								<!-- <el-input style="width: 240px" v-model="form.khhCode"></el-input>  -->
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button style="width: 116px" @click="close">取消</el-button>
			<el-button type="success" style="width: 150px" @click="submit">提交</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { saveBank, editBank } from '@/api/projectManage'
import { uploadFile, deleteFile } from '@/api/fileApi'
import { mapGetters } from 'vuex'
export default {
	data() {
		return {
			baseUrl: window.globalUrl.HOME_API,
			title: '新增银行账户',
			type: 'add',
			form: {
				account: '',
				khh: '',
				khm: '',
				khhCode: '',
				jgAccount: '',
				jgKhh: '',
				jgKhm: '',
				jgProvince: '',
				jgCity: '',
				jgArea: '',
				xzqbh: '',
        orderCardId: '',
        socialCreditId: '',
				id: null,
			},
			prop: {
				label: 'areaName',
				children: 'children',
				value: 'id',
				fullPathName: 'fullPathName',
				checkStrictly: true,
				emitPath: false,
			},
			fileList1: [],
			checked: false,
		}
	},
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		regionOptions: {
			type: Array,
			default: function () {
				return []
			},
		},
	},
	watch: {
		type: function () {
			if (this.type == 'edit') {
				if (this.form.khhCode) {
					this.getViewUrl1(this.form.khhCode)
				}
			}
		},
		visible: function () {
			if (this.visible) {
				if (this.identityObj) {
					this.form.xzqbh = this.identityObj.areaCode
				}
			}
		},
	},
	computed: mapGetters(['userInfo', 'identityObj']),
	methods: {
		beforeUpload(file) {
			// 文件上传之前的函数
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
			const isLt2M = file.size / 1024 / 1024 < 6
			if (!isJPG) {
				this.$message.error('上传图片为 JPG 或 PNG 格式!')
				this.isUpload = false
			}
			if (!isLt2M) {
				this.$message.error('上传图片大小不能超过 6MB!')
				this.isUpload = false
			}
			return isJPG && isLt2M
		},
		async upload(file, filelist) {
			let res = await this.uploadFile(file, filelist)
		},
		async uploadFile(file, filelist) {
			let param = new FormData()
			param.append('catalog', 'vip')
			param.append('file', file.raw)
			let res = await uploadFile(param)
			if (res.code == 0) {
				this.form.khhCode = res.data.url
			}
			this.fileList1 = filelist
			console.log(this.fileList1)
		},
		removeXczp(file, fileList) {
			this.fileList1 = fileList
			this.form.khhCode = null
			this.deleteFile(file.url2)
		},
		async deleteFile(url) {
			let res = await deleteFile({ ossFilePath: url })
			if (res.code == 0 && res.data == true) {
				console.log('删除成功')
			}
		},
		getViewUrl1(data) {
			let item = { url: this.baseUrl + 'admin/file/get?ossFilePath=' + data }
			this.fileList1 = [item]
		},
		async submit() {
			// 提交
			let res = {}
			if (this.type == 'add') {
				res = await saveBank(this.form)
			} else if (this.type == 'edit') {
				res = await editBank(this.form)
			}
			let { status, data, message } = res
			if (status == 200) {
				this.$message.success(message)
				this.close()
			} else {
				this.$message.error(message)
			}
		},
		close() {
			// 关闭
			this.$emit('close', false)
		},
	},
}
</script>

<style lang="less" scoped>
/deep/.el-form-item {
	display: flex;
}
/deep/.el-form-item__label {
	min-width: 170px;
	text-align: right;
}
</style>
